.button {
    position: absolute;
    top: -40px;
    left: 0;
    font-size: 0.8rem;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    border: 0;
    outline: none;
    background-color: #1b4371;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
}

.badge {
    fill: #ffffff;
}

.button>span {
    margin-left: 1rem;
}