.footer {
    display: flex;
    flex-direction: column;
}

.firstPart {
    background-color: #f7f7f7;
}

.contentFirstPart {
    box-sizing: border-box;
    width: 95%;
    margin: 0 auto;
    padding: 35px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.icon {
    width: 35px;
    height: 35px;
    margin-right: 15px;
}

.info {
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contacts {
    display: flex;
    flex-direction: column;
    list-style: none;
}

.contactsText {
    font-size: 14px;
    line-height: 26px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    display: flex;
}

.contactsText a {
    text-decoration: none;
    font-size: 14px;
    line-height: 26px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.secondPart {
    background-color: var(--header-bg);
    padding: 25px 0;
}

.privacyPolicy {
    text-align: center;
}

.envelopeIcon {
    width: 16px;
    height: 25px;
    margin-right: 5px;
}

.communicationsIcon {
    width: 14px;
    height: 25px;
    margin-right: 5px;
}

.privacyPolicySpan {
    cursor: pointer;
    font-size: 12px;
    line-height: 41px;
    color: rgba(255, 255, 255, 0.42);
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}