.counterTitle {
    margin-left: 10px;
    font-size: 11px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.counter {
    margin-left: 5px;
    font-size: 11px;
    color: #1b4371;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    background-color: #f7f7f7;
    padding: 0 3px;
}

@media (max-width: 1300px) {
    .counterTitle {
        width: 35%;
        margin-left: 10px;
        font-size: 11px;
        color: #292929;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
    }
}