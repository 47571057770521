.header_container {
    width: 100%;
    height: 7vh;
    background-color: var(--header-bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerContent {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo_container {
    margin: 0;
    padding: 0;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo_container a {
    cursor: pointer;
}

.logo_title {
    font-size: 1.1rem;
    color: #f7f7f7;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-left: 2vw;
    letter-spacing: 0.3px;
}

.questionCounter_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    width: 52px;
    height: 26px;
}

.button {
    background-color: transparent;
    border: 1px solid var(--main-300);
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    fill: #888d99;
}

.button:hover {
    background-color: #1b4371;
    border: 0;
    border: 1px solid #1b4371;
}

.button:hover .arrow {
    fill: white;
}

.count {
    font-size: 14px;
    color: #f7f7f7;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: center;
    margin: 0 1rem;
}

.days_container {
    font-size: 14px;
    color: #586472;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 7vh;

    border-left: 1px solid #586472;

    padding-left: 2vw;
}

.days_container span {
    color: #ffffff;
}

.text {
    font-size: 1.1rem;
    color: #f7f7f7;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 0.3px;
}