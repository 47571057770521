.main {
  width: 100%;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow-y: auto; */
}

.mainContent {
  width: 95%;
  min-height: 74vh;
  /* overflow-y: auto; */
  margin: 7vh auto 2vh;
  display: flex;
  position: relative;
}

/* .checkBoxContainer {
    width: 95%;
    margin: 4vh auto 3vh;
    display: flex;
    justify-content: space-between;
} */

.checkBoxWrapper {
  position: relative;
  width: 95%;
  height: 18vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: -3px;
}

.percentage {
  width: 130px;
  height: 130px;
  margin-left: 1rem;
}

.criteria {
  width: 100%;
  height: 17vh;
  border: 1px solid #c1c1c1;
  overflow-y: auto;
}

.checkTitle {
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 14px;
  color: #292929;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.criteria ol {
  margin-left: 2rem;
  list-style: none;
}

.rightCriteria {
  font-size: 14px;
  line-height: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  color: #169b20;
  display: flex;
  position: relative;
}

.criteriaIcon {
  position: absolute;
  top: 3px;
  left: -25px;
  content: url("../../assets/icons/done.svg");
  width: 20px;
  height: 20px;
}

.wrongCriteria {
  font-size: 14px;
  line-height: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  color: #dc3014;
  display: flex;
  position: relative;
}

/* НАЧАЛО СТИЛИЗАЦИИ ПРАВОЙ ЧАСТИ (КОД И РЕЗУЛЬТАТ): */

.codeAndResult_container {
  margin-left: 3%;
  width: 67%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.code_container {
  width: 100%;
  height: calc(38vh + 1.5rem);
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  overflow: hidden;
  padding-top: 1.5rem;
}

.box {
  width: 100%;
  height: 38vh;
  position: relative;
  /* overflow-x: hidden; */
}

.boxCss {
  width: 100%;
  height: 38vh;
  position: relative;
  /* overflow-x: hidden; */
}

.containerHTML {
  width: 100%;
  height: 38vh;
}

.containerCSS {
  position: relative;
  width: 100%;
  height: 38vh;
}

.codeTitle {
  position: absolute;
  top: -17px;
  left: 0;
  color: black;
  font-size: 14px;
  color: #292929;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.result_container {
  width: 100%;
  line-height: 0;
  position: relative;
}

/* .result_container::after {
    position: absolute;
    top: 40px;
    left: -48px;
    content: "Результат:";
    transform: rotate(-90deg);
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  } */

/* .result_container::after {
    position: absolute;
    top: -15px;
    right: 0;
    content: "Результат:";
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  } */

.activeTab {
  background-color: orange;
  color: white;
}