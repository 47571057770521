/* стилизация текста который приходит в компонент TheoryAndTaskContainer - start*/
div.browser {
  padding: 1rem 1.5rem;
  border: 1px solid #c1c1c1;
  background-color: #fff;
  margin: 1rem 0 0;
  position: relative;
  margin-bottom: 20px;
}

div.warning {
  background-color: rgba(254, 200, 48, 0.1);
  color: #292929;
  font-size: 16px;
  line-height: 26px;
  font-family: "Montserrat", sans-serif;
  margin: 1rem 0;
  padding: 20px;
  border: 1px solid #fec830;
}

/* div attention - start */
div.attention {
  font-family: var(--font-theory);
  background-color: #fa383e;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 0.4rem;
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
}

div.attention p {
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  margin: 0 0 10px 0;
}

div.attention p:last-child {
  margin: 0;
}

div.attention h5 {
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  padding-left: 20px;
  position: relative;
  margin: 0 0 10px 0;
}

div.attention h5::before {
  content: url(../assets/icons/fire/fire.svg);
  position: absolute;
  top: 0;
  left: 0;
}

div.attention li {
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  margin: 0 0 10px 0;
}

/* div attention - end */

/* div useful - start */
div.useful {
  font-family: var(--font-theory);
  background-color: #ebedf0;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 0.4rem;
  font-family: var(--font-theory);
  color: rgb(28, 30, 33);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
}

div.useful p {
  font-family: var(--font-theory);
  color: rgb(28, 30, 33);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  margin: 0 0 10px 0;
}

div.useful p:last-child {
  margin: 0;
}

div.useful h5 {
  font-family: var(--font-theory);
  color: rgb(28, 30, 33);
  font-size: 16px;
  line-height: 26.4px;
  padding-left: 20px;
  position: relative;
  margin: 0 0 10px 0;
}

div.useful h5::before {
  content: url(../assets/icons/theoryInfo/theoryInfo.svg);
  position: absolute;
  top: 2px;
  left: 0;
}

div.useful li {
  font-family: var(--font-theory);
  color: rgb(28, 30, 33);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  margin: 0 0 10px 0;
}

/* div useful - end */

/* div interesting - start */
div.interesting {
  font-family: var(--font-theory);
  background-color: #00a400;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 0.4rem;
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
}

div.interesting p {
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  margin: 0 0 10px 0;
}

div.interesting p:last-child {
  margin: 0;
}

div.interesting h5 {
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  padding-left: 20px;
  position: relative;
  margin: 0 0 10px 0;
}

div.interesting h5::before {
  content: url(../assets/icons/lightBulb/lightBulb.svg);
  position: absolute;
  top: 2px;
  left: 0;
}

div.interesting li {
  font-family: var(--font-theory);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 26.4px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  margin: 0 0 10px 0;
}

/* div interesting - end */

/* стилизация текста который приходит в компонент TheoryAndTaskContainer - end*/
