.wrapper {
  height: 100vh;
  overflow-y: auto;
  border: 1px solid #c1c1c1;
}

.theoryAndTask_container {
  padding: 1rem 1.5rem;
  font-size: 16px;
  color: #6b6f7a;
  line-height: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.theoryAndTask_container * {
  font-family: "Montserrat", sans-serif;
}

.theoryAndTask_container img {
  display: block;
  width: 100%;
  max-width: 760px;
  margin: 0 auto 20px;
  border-radius: 0.4rem;
}

.theoryAndTask_container pre {
  margin-bottom: 20px;
}

.theoryAndTask_container code {
  background-color: #ebedf0;
  color: rgb(28, 30, 33);
  font-size: 15px;
  line-height: 25px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  margin: 0;
  border-radius: 3px;
  padding: 3px 8px;
  font-weight: 600;
  white-space: pre-wrap;
}

.theoryAndTask_container pre>code {
  background-color: var(--header-bg);
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  white-space: pre;
  padding: 20px;
  border-radius: 0.4rem;
  color: #f07178;
  font-weight: 500;
  white-space: wrap;
}

.theoryAndTask_container>h2 {
  font-size: 32px;
  letter-spacing: 0.3px;
  line-height: 40px;
  color: rgb(28, 30, 33);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.theoryAndTask_container > h2 > code {
  font-size: 32px;
  letter-spacing: 0.3px;
  line-height: 40px;
}

.theoryAndTask_container summary {
  background-color: #ebedf0;
  display: block;
  padding: 10px 10px 10px 45px;
  position: relative;
  font-size: 22px;
  letter-spacing: 0.3px;
  line-height: 26px;
  color: rgb(28, 30, 33);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 26px;
  margin-bottom: 15px;
  border-radius: 0.4rem;
  user-select: none;
  cursor: pointer;
}

.theoryAndTask_container summary::before {
  content: url('../../assets/icons/book/book.svg');
  position: absolute;
  top: 12px;
  left: 10px;
}

.theoryAndTask_container summary::-webkit-details-marker {
  display: none;
}

.theoryAndTask_container details summary::after {
  content: url('../../assets/icons/arrow_in_circle/down-arrowhead-in-a-circle.svg');
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

/* Добавляем собственный маркер для открытого состояния */
.theoryAndTask_container details[open] summary::after {
  content: url('../../assets/icons/arrow_in_circle/up-arrowhead-in-a-circle.svg');
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.theoryAndTask_container h3[class="task"] {
  background-color: #ebedf0;
  padding: 10px 10px 10px 45px;
  position: relative;
  font-size: 22px;
  letter-spacing: 0.3px;
  line-height: 26px;
  color: rgb(28, 30, 33);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 26px;
  margin-bottom: 15px;
  border-radius: 0.4rem;
}

.theoryAndTask_container h3[class="task"]::before {
  content: url('../../assets/icons/writing/writing.svg');
  position: absolute;
  top: 10px;
  left: 10px;
}

.theoryAndTask_container h3[class="test"] {
  background-color: #ebedf0;
  padding: 10px 10px 10px 45px;
  position: relative;
  font-size: 22px;
  letter-spacing: 0.3px;
  line-height: 26px;
  color: rgb(28, 30, 33);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 26px;
  margin-bottom: 15px;
  border-radius: 0.4rem;
}

.theoryAndTask_container h3[class="test"]::before {
  content: url('../../assets/icons/flask/flask.svg');
  width: 20px;
  height: auto;
  position: absolute;
  top: 10px;
  left: 10px;
}

.theoryAndTask_container p {
  font-size: 16px;
  color: rgb(28, 30, 33);
  line-height: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
}

.theoryAndTask_container ul {
  padding-left: 20px;
}

.theoryAndTask_container ol {
  padding-left: 20px;
}

.theoryAndTask_container li {
  font-size: 16px;
  line-height: 26px;
  color: rgb(28, 30, 33);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
}

.theoryAndTask_container strong {
  color: #292929;
}

.theoryAndTask_container li strong {
  color: #292929;
}

/* styling table in theoryAndTask_container - start */
.theoryAndTask_container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.theoryAndTask_container table thead tr {
  color: rgb(28, 30, 33);
  font-weight: bold;
  background-color: #f7f7f7;
}
.theoryAndTask_container table thead tr th {
  border: 1px solid #e8e9eb;
  padding: 10px;
}
.theoryAndTask_container table thead tr td {
  border: 1px solid #e8e9eb;
}
.theoryAndTask_container table tbody tr td {
  border: 1px solid #e8e9eb;
  padding: 10px;
}
/* styling table in theoryAndTask_container - end */

.layout {
  width: 100%;
  max-height: 75vh;
  cursor: pointer;
}

.layoutTitle {
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 28px;
  color: #292929;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding-left: 1.5rem;
}

.layoutText {
  font-size: 16px;
  color: #2a2a2a;
  line-height: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding-left: 1.5rem;
}